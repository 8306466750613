.cursor {
  cursor: pointer;
}

.section-title h1 {
  font-size: 44px !important;
}

.section-title h6 {
  font-size: 20px;
  color: #3c3c3c;
}

.Icons_Color {
  color: #16152d !important;
}

.sec-5-main {
  margin-top: 25vh;
  margin-bottom: 8vh;
}

.iconToggle {
  color: #fff !important;
}

.DropDownItem.active {
  color: #000;
  text-decoration: none;
  background-color: #fff;
}

.DropDownItem.active:hover {
  color: #000;
  text-decoration: none;
  background-color: #e1e3e1;
}

.secIcon {
  height: 24px;
  width: 24px;
  background: #39393911;
  border-radius: 33px;
  align-items: center;
  justify-content: center;
}

.icon-6 {
  margin-left: -8px;
  margin-top: -4px;
}

.CardHeader {
  background: none;
  border: none;
}

.CardFooter {
  background: #fff !important;
  border: none !important;
}

.sec-5-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  height: 200px;
}

.sec-6-subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #393939;
  height: calc(100% - 20px);
}

.sec-6-main {
  height: calc(100% - 1.25rem) !important;
}

.dropdownHover {
  display: none;
}

.nav-hover:hover.dropdownHover {
  display: block;
}

.navbar-nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

.navbar-nav .dropdown-menu {
  position: fixed !important;
  border-radius: 0px;
  box-shadow: 1px 5px 10px rgb(15 34 58 / 12%);
  border: 0;
  left: 0;
  background-color: transparent;
}

.child-dropdown {
  width: 100%;
  display: flex;
  list-style: none;
}

ul.child-dropdown li {
  list-style: none;
  float: left;
  border-radius: 5px;
  width: 33.333%;
}

.cardTeam {
  border-radius: 15px;
}

.cardTeam img {
  height: 95px;
  width: 95px;
  margin-top: 20px;
  background: #f1f1f1;
  object-fit: cover;
  border-radius: 100%;
}

.cardTeam h1 {
  color: #2e2f46;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1;
}

.cardTeam p {
  color: #494a60;
  font-weight: 400;
  font-size: 0.8rem;
  opacity: 0.8;
}

.cardhover:hover {
  background-color: #097856;
  color: #fff !important;
  text-decoration: none;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.cardhover {
  text-decoration: none !important;
  color: #000 !important;
}

.IconArrow {
  color: #000 !important;
}

#demo {
  background: linear-gradient(112deg, #ffffff 50%, #f6f6f7 50%);
  max-width: 900px;
  margin: auto;
}

.Caption {
  position: initial;
  z-index: 10;
  padding: 5rem 8rem;
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1rem;
}

.Caption img {
  width: 8rem;
  border-radius: 5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#ImageCaption {
  font-style: normal;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.ArrowIcon {
  color: #3c3c3c;
  background-color: #e1e3e1;
  border-radius: 50px;
  margin: 1rem;
  box-shadow: 1px 1px 1px 2px #42424240;
}

.carousel-control-prev {
  justify-content: flex-start;
}

.carousel-control-next {
  justify-content: flex-end;
}

.carousel-control-prev,
.carousel-control-next {
  transition: none;
  opacity: unset;
}

.card-border {
  border-radius: 40px !important;
  box-shadow: 2px 2px 2px #ddd;
}

.card-border:hover {
  box-shadow: 0px 10px 10px #ddd;
}

.MiddleCard {
  width: 70%;
  margin-top: 7.4rem;
  margin-bottom: 7.4rem;
}

.para {
  height: calc(100% - 1.25rem);
}

.Card_Box {
  height: calc(100% - 1.25rem) !important;
  border-radius: 20px;
}

.Card_Box:hover {
  box-shadow: 0px 10px 10px #ddd;
}

.SolutionBanner {
  height: 400px;
}

.ContactBanner {
  height: 450px;
}

.SolutionBanner h1 {
  font-weight: 700 !important;
}

.Logitech {
  padding: 35px 50px;
  box-shadow: 0px 64px 100px -48px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-top: -200px;
}

.Fintech {
  padding: 35px 50px;
  box-shadow: 0px 64px 100px -48px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}

.BgAdavantage {
  background: #1e1844;
  padding: 30px 50px;
  color: #fff;
}

.ManageDocs p {
  text-align: justify;
}

.ManageDocs h4 {
  font-size: 24px !important;
}

.TakeControl {
  border-radius: 40px;
}

.UnderLine {
  height: 2px !important;
  width: 14%;
  margin-left: 443px;
}

.InsureCard {
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 64px 100px -48px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}

.InsureCard p {
  height: 130px;
}

.Digitise {
  background: #ffffff;
  border: 1px solid #797979;
  border-radius: 20px;
}

.FinCard {
  background: #ffffff;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  border-radius: 24px;
}

.FeaturesCard {
  color: #000;
}

.FeaturesCardStyle {
  height: 230px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  background: transparent;
  color: #fff;
  line-height: 1.5;
}

.FeaturesCard p {
  margin-bottom: 5px;
}

.CarosuelBg {
  background: #010018;
  box-shadow: 0px 64px 64px -48px rgb(15 15 15 / 10%);
  border-radius: 24px;
  color: white;
  background-image: url(../images/Fintech/paperless-process.png);
  height: calc(100% - 2rem);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bgImage {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 24px;
}

.CarosuelCard {
  padding: 51px;
  border-radius: 10px;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #070536 !important;
  background: #1a909d;
  border-radius: 100px;
  padding: 20px;
}

.NavPills .NavItem .nav-link {
  background: #fff;
  border-radius: 0px;
  color: #23a7b6 !important;
  font-size: 24px;
  text-align: center;
  margin: auto;
  padding-top: 26px;
}

.NavPills .NavItem .nav-link.active,
.NavPills .show>.nav-link {
  background: #23a7b6;
  border-radius: 0px;
  color: #fff !important;
  font-size: 24px;
  text-align: center;
  margin: auto;
  padding-top: 15px;
}

.VaktSection {
  background: #ffffff;
  border-radius: 40px;
}

.IconsStyle ul li {
  border-radius: 50px;
  text-align: center;
  background: #ffffff1e;
  justify-content: center;
}

.IconsStyle>ul>li>.nav-link {
  margin: -4px -7px -5px -2px !important;
}

.IconsStyle>ul>li:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.BookIcons {
  height: 50px;
  width: 50px;
}

.FormStyling input {
  color: #000 !important;
}

.FormFloating input {
  border: 1px solid #cbd6e2 !important;
  background-color: #f5f8fa !important;
  border-radius: 12px !important;
}

.FormFloating select {
  border: 1px solid #cbd6e2 !important;
  background-color: #f5f8fa !important;
  border-radius: 12px !important;
}

.FormFloating textarea {
  border: 1px solid #cbd6e2 !important;
  background-color: #f5f8fa !important;
  border-radius: 12px !important;
}

.BookFormCard {
  border-radius: 20px;
}

.contactUsBg {
  background-color: #1e1844;
  color: #fff;
  border-radius: 15px;
}

.ContactCard {
  padding: 3rem;
  box-shadow: 0px 64px 100px -48px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid #e5e5e5;
}

.BlogCards {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}

.BlogCards:hover {
  box-shadow: 2px 2px 2px #ddd;
}

.BlogCardsImage img {
  border-radius: 12px 0px 0px 12px;
}

.BlogRightCardsImage {
  border-radius: 4px 0px 0px 4px;
}

.RightCardText {
  font-size: 13px;
}

.published-description a {
  font-size: 13px;
}

.RightCard {
  border-radius: 12px;
}

.icon8 {
  width: 15px;
  height: 15px;
}

.DateDuration {
  font-size: 10px;
  font-weight: 700;
}

.RightCard:hover {
  box-shadow: 0px 6px 16px -6px rgba(1, 1, 1, 0.5);
}

#NewsBanner {
  background-image: url("../images/Background-images/News-and-event-background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

.BlogCardHeight {
  height: 200px;
}

.blogHeadheight {
  height: 190px;
  background-color: #fff;
  border: none;
  margin-bottom: 20px;
}

.blogHeadheight img {
  height: 200px;
  border-radius: 12px;
}

.BlogFooter {
  background-color: #fff;
  border: none;
}

.icon10 {
  color: #000;
}

.InsuranceCard {
  border: 1px solid #fff;
  background: #fff;
  padding: 20px;
  box-shadow: -2px 2px 10px 5px rgb(0 0 0 / 15%);
  border-radius: 20px;
}

.SolutionNewBanner {
  background-image: url("../images/SolutionNewBanner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-bottom: 30px;
  position: relative;
}

.ImageBanner {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 64px 100px -48px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  border-radius: 24px;
  color: #fff;
}

.ImageBanner img {
  height: 275px;
}

.Readbtn {
  height: 40px;
  left: 0px;
  top: 262px;
  color: #fff;
  background: #1a909d;
  border-radius: 8px;
}

.LogiCards {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  border-radius: 24px;
  color: #fff;
}

.LogiCards h4 {
  font-size: 32px;
}

.LogiCardbody {
  height: 385px;
}

.InsureCardBody {
  height: calc(100% - 1.25rem);
}

.InsureCardBody h4 {
  height: 60px;
}

.LogiCardbody h4 {
  height: 80px;
}

.validation-input {
  border: 2px solid rgb(255, 99, 71) !important;
  background-color: #f5f8fa !important;
  border-radius: 6px !important;
}

.fontValid {
  font-size: 12px;
}

.insurecarosuel {
  background: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  color: #000;
  border-radius: 15px;
  height: calc(100% - 1.25rem);
}

.insurecarosuel img {
  border-radius: 15px;
}

.EasyStepCard {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: calc(100% - 20px) !important;
}

.SetprocessCard {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #e5e5e5;
  backdrop-filter: blur(50px);
  border-radius: 15px;
}

.SetprocessImage img {
  border-radius: 0px 18px 18px 0px;
  height: 300px;
}

.SetprocessImagetwo img {
  border-radius: 18px 0px 0px 18px;
  height: 300px;
}

.documentslist li {
  margin-top: 15px;
  margin-bottom: 15px;
}

.OnbordingHr {
  width: 157px;
  height: 1.5px !important;
  left: 0px;
  top: 32px;
  background: #000000;
}

.SolutionNewBanner h1 {
  font-weight: 700 !important;
}

.NewsBanner h1 {
  font-weight: 700 !important;
}

.insuranceinput {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  padding: 20px;
  box-shadow: 1px 5px 10px rgb(15 34 58 / 12%);
}

.AccordionInsurance {
  border-radius: 12px !important;
  border: 1px solid #f7f7f7 !important;
  box-shadow: 1px 5px 10px rgb(15 34 58 / 12%) !important;
}

.AccordionInsurance .accordion-header {
  background: #f7f7f7 !important;
  border-radius: 12px !important;
}

.SearchGroup input {
  border-radius: 10px 0px 0px 10px;
}

.SearchGroup span {
  border-radius: 0px 10px 10px 0px;
  background: #30b2cb;
  color: #fff;
}

#headingOne button {
  background: #f5f8fa !important;
  border-radius: 10px;
}

.documentslist p {
  text-align: justify;
}

.ParaHeight {
  height: calc(100% - 1.25rem);
  border-radius: 12px;
}

.logCardsImage img {
  border-radius: 11px;
  height: 150px;
  width: 150px;
}

#BannerImage {
  background-image: url("../images/Background-1.svg");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#BannerImage img {
  height: 450px;
}

.nav-menu:hover {
  color: #00b7c2 !important;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.head-button2 {
  width: 115px;
  height: 43px;
  left: 1156.5px;
  top: 23.5px;
  color: #00b7c2;
  background: #16152d;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
}

.head-button2:hover {
  font-size: 16px;
  line-height: 19px;
  background-color: #fff;
  color: #23a7b6;
}

#InsureBannerImage {
  background-image: url("../images/Background-2.svg");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#InsureBannerImage img {
  height: 450px;
}

.helpcompoenentbg {
  background: #ebf9fa;
  border-radius: 10px;
}

.locationIframe iframe {
  border-radius: 12px;
  width: 350px;
  height: 190px;
  text-align: center;
}



.nav-item a:hover {
  color: #00b7c2;
}

.search-foot input:hover {
  background-color: transparent;
}

.sec-5-icon:hover {
  transform: scale(1.2);
}

.TextColor {
  color: #23a7b6;
}

#sec-banner {
  background-image: url("../images/Background-1.svg");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

.SolutionLogisection {
  height: calc(100% - 1.25rem) !important;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 15px;
}

.SolutionLogisection:hover {
  background-color: #16152d;
  color: #fff;
}

.SolutionInspectsection {
  height: calc(100% - 1.25rem) !important;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 15px;
}

.ListStyleImage {
  position: relative;
  list-style: none;
  margin-left: 5px;
  padding-left: 2rem;
}

.ListStyleImage li:before {
  content: "";
  border: 3px solid #23a7b6;
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
  position: absolute;
  left: 1px;
  margin-top: 6px;
}

.FintechList {
  border: 1px solid #c4c4c4;
  border-radius: 10px 0px 0px 10px;
}

.FintechListIcon {
  background: #16152d;
  border-radius: 0px 10px 10px 0px;
}

.Seemore {
  background: linear-gradient(101.59deg, #57d0ff 11.25%, #23a7b6 92.44%);
  border-radius: 23px;
  border: none;
  padding: 10px 25px;
  color: #fff;
}

.Seemore:hover {
  background: linear-gradient(101.59deg, #097856 11.25%, #23a7b6 92.44%);
  border-radius: 23px;
  border: none;
  padding: 10px 25px;
  color: #fff;
}

.btn:hover {
  background: linear-gradient(101.59deg, #097856 11.25%, #23a7b6 92.44%);
  border-radius: 23px;
  border: none;
  padding: 10px 25px;
  color: #fff;
}

.buttonOne {
  background: #26676b;
  box-shadow: inset 0px 0px 100px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.buttonThree {
  background: #fa9e1f;
}

.buttonFour {
  background: #ffc720;
}

.SmartCard .card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  height: calc(100% - 1.25rem);
}

.LC-card {
  border: 1px solid #c4c4c4;
  border-radius: 15px;
}

.LC-card img {
  border-radius: 14px 0px 0px 14px;
  width: auto;
}

.EKYC-card .card-header {
  background-color: #fff !important;
  border: none;
  text-align: center;
}

.EKYC-card .card {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  height: calc(100% - 1.25rem);
}

.EKYC-card .card p {
  text-align: justify;
}

.scrollbar2 {
  height: 350px;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.MarketSection p {
  height: 165px !important;
}

.MarketSection {
  border-radius: 20px;
}

.MarketCard {
  height: calc(100% - 1.25rem);
  border-radius: 15px;
}

.MarketCard .card-footer {
  border-radius: 15px !important;
}

.scroll {
  height: 270px;
  overflow: auto;
  float: left;
  margin: 0 10px;
}

.scroll1::-webkit-scrollbar {
  width: 0px;
}

.scroll1::-webkit-scrollbar-track {
  background: #ddd;
}

.scroll1::-webkit-scrollbar-thumb {
  background: #666;
}

.solutioncard {
  border-radius: 20px;
  height: calc(100% - 1.25rem);
}

.solutioncard img {
  height: 150px;
}

.solutioncard p {
  font-size: 16px;
  height: 80px;
}

.Whowearecarosuel {
  background: #010018;
  border-radius: 24px;
  color: white;
  height: calc(100% - 1.25rem);
}

.Whowearecarosuel img {
  height: 350px;
}

.carousel-item {
  height: calc(100% - 1.25rem) !important;
}

.scrollbar {
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 25px;
}

#style-4::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(222, 223, 224, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: rgba(222, 223, 224);
}

.Publisheddate {
  font-size: 12px;
}

/* .published-description {
  height: 220px;
} */
.sec-4-icon:hover {
  box-shadow: 1px 5px 10px rgb(15 34 58 / 12%);
}

.solutioncard:hover {
  box-shadow: 1px 5px 10px rgb(15 34 58 / 12%);
}

.OurStory {
  position: relative;
  overflow-x: hidden;
  background: #fff;
  padding-bottom: 2rem;
}

.OurStory_Top {
  text-align: center;
  padding: 2rem;
}

.OurStory_Top h1 {
  color: #2e2f46;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.4;
}

.OurStory_Top p {
  line-height: 1.8;
  color: #494a60;
  font-weight: 400;
  font-size: 1rem;
}

.OurStory_Box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 0 auto;
}

.OurStory_TimeLine div {
  display: grid;
  grid-template-columns: 100px 50px auto;
  align-items: center;
  height: 200px;
}

.OurStory_TimeLine div h2 {
  color: #2e2f46;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4;
}

.OurStory_TimeLine div p {
  line-height: 1.4;
  color: #494a60;
  font-weight: 400;
  font-size: 0.9rem;
}

.OurStory_TimeLine div span {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #494a60;
  position: relative;
}

.OurStory_TimeLine div span::before {
  content: "";
  height: 100px;
  width: 4px;
  background: #2e2f46;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
}

.OurStory_TimeLine div span::after {
  content: "";
  height: 100px;
  width: 4px;
  background: #2e2f46;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.OurStory_TimeLine div:nth-child(1) span::before {
  display: none;
}

.OurStory_TimeLine div:nth-last-child(1) span::after {
  display: none;
}

.OurStory_Images {
  display: grid;
  gap: 1rem;
}

.OurStory_Images div {
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.OurStory_Images div img {
  height: auto;
  object-fit: cover;
  width: 80%;
  transition: 0.2s;
}

.TeamSection_Box {
  display: grid;
  place-content: center;
  height: 70vh;
  max-height: 1500px;
}

.TeamSection_Box .TeamSection_Card {
  width: 100%;
  height: 450px;
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -2px 6px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid #f1f1f1;
  padding: 0.4rem;
  overflow: hidden;
}

.TeamSection_Card_Left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #f1f1f1;
  border-radius: 10px;
}

.TeamSection_Card_Left img {
  padding: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 100%;
  object-position: center;
  max-width: 230px;
  max-height: 230px;
  box-shadow: 0px 0px 0px 2px #ccc;

}

.TeamSection_Card_Left div {
  display: flex;
  gap: 1rem;
}

.TeamSection_Card_Left div button {
  width: 35px;
  height: 35px;
  border: none;
  padding: 0.4rem;
  border-radius: 100%;
  display: grid;
  place-content: center;
  background: rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  cursor: pointer;
}

.TeamSection_Card_Left div button:hover {
  background: rgba(0, 0, 0, 0.4);
}

.TeamSection_Card_Left div button:active {
  transform: translateY(-3px);
}

.TeamSection_Card_Right {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.TeamSection_Card_Right div {
  display: grid;
  justify-content: flex-start;
}

.TeamSection_Card_Right div:nth-child(1) {
  display: flex;
  align-items: center;
}

.TeamSection_Card_Right div:nth-child(1) h2 {
  font-weight: 600;
  font-size: 1.7rem;
  text-transform: capitalize;
  text-align: left;
}

.TeamSection_Card_Right div:nth-child(1) span {
  margin: 1px 10px 3px 12px;
  display: grid;
  place-content: center;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: 1px solid #3f979d;
  cursor: pointer;
}

.TeamSection_Card_Right div:nth-child(2) {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0.6rem;
}

.TeamSection_Card_Right div:nth-child(2) span {
  font-size: 0.85rem;
  opacity: 0.7;
  margin-right: 0.4rem;
}

.TeamSection_Card_Right div:nth-child(3) {
  padding: 0 0;
  overflow: auto;
}

.TeamSection_Card_Right div:nth-child(3) p {
  font-size: 0.98rem;
  line-height: 1.7;
  text-align: left;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  -webkit-box-orient: vertical;
}

.TeamSection_Card_Right div:nth-child(3)::-webkit-scrollbar {
  appearance: none;
  height: 8px;
  width: 6px;
}

.TeamSection_Card_Right div:nth-child(3)::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ddd;
}

.TeamSection_Card_Right div:nth-child(4) {
  justify-self: flex-end;
  padding-top: 0.6rem;
}

.TeamSection_Card_Right div:nth-child(4) button {
  padding: 0.6rem 2rem;
  margin-top: 0px;
}

.Linkedin-icon {
  margin-bottom: 18px;
}

#SolutionBanner-Bg {
  background-image: url("../images/Background-images/Sol-Header-BACKGROUND.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#TradeTech-Bg {
  background-image: url("../images/Background-images/TRADE-TECH-BACKGROUND.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#TradeTech-Bg img {
  height: 450px;
}

#LogiTech-Bg {
  background-image: url("../images/Background-images/LOGI-TECH-Background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#LogiTech-Bg img {
  height: 450px;
}

#InsureBanner-Bg {
  background-image: url("../images/Background-images/INSURE-TECH-Background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#InsureBanner-Bg img {
  height: 450px;
}

#Fintech-bg {
  background-image: url("../images/Background-images/FIN-TECH-BACKGROUND.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#Fintech-bg img {
  height: 450px;
}

#InspectTech-Bg {
  background-image: url("../images/Background-images/INSPECT-TECH-Background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#InspectTech-Bg img {
  height: 450px;
}

#ContactBanner-bg {
  background-image: url("../images/Background-images/CONTACT-US-BACKGROUND.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#HelpDesk-Bg {
  background-image: url("../images/Background-images/Help-Desk-Background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
}

#HomeBg {
  background-image: url("../assets/Images/cover.jpeg");
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust opacity as needed */
  background-blend-mode: darken;
}

.sec-5-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

#Shipping-Bg {
  background-image: url("../images/Background-images/Shipping-Marketplace-background.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
}

.searchId {
  background: #e1e2e3;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.05);
}

.searchId .input-group {
  background-color: transparent !important;
  border: none;
  border: 0.5px solid #c4c4c4;
  border-radius: 8px;
}

.searchId .input-group span {
  background-color: transparent !important;
  border: none;
}

.searchId .input-group input {
  background-color: transparent !important;
  border: none;
  color: #000 !important;
}

.searchId .input-group input::placeholder {
  color: #000 !important;
  opacity: 1;
  /* Firefox */
}

.searchId .input-group select {
  background-color: transparent !important;
  border: none;
}

.searchId .form-select {
  background-image: url(../images/chevron-down.png);
  background-size: 12px;
  color: #000 !important;
}

.Register {
  background: #16152d;
  border-radius: 10px;
}

.btnSearch {
  color: #00b7c2;
  /* background: #16152d; */
  border: 1px solid #16152d;
  border-radius: 24px;
}

.btnSearch .iconify {
  color: #16152d !important;
  height: 16px;
}

.pagination .page-item {
  font-size: 12px !important;
}

.pagination .page-item .page-link {
  font-weight: 500;
}

.pagination .page-item .page-link .iconify {
  height: 17px;
}

/* media queries */

@media (max-width: 280px) {
  .LogiCardbody {
    height: 670px !important;
  }

  .LogiCardbody h4 {
    height: 150px;
  }
}

@media (max-width: 380px) {
  .LogiCardbody {
    height: 600px !important;
  }

  .LogiCardbody h4 {
    height: 120px;
  }

  .sec-b-text-box {
    height: 500px !important;
  }
}

@media (max-width: 576px) {
  .sec-b-text-box {
    height: 400px !important;
  }

  .LogiCardbody {
    height: 300px !important;
  }

  .LogiCardbody h4 {
    height: 80px;
  }

  .BlogcardstextData {
    margin: 20px;
  }

  .SmartCard .card {
    width: 300px;
    height: calc(100% - 1.25rem);
  }

  .MarketSection p {
    height: 150px !important;
  }
}

@media (max-width: 768px) {
  .Caption img {
    width: 4rem;
    border-radius: 4rem;
    margin-top: 1rem;
  }

  #ImageCaption {
    font-style: normal;
    font-size: 0.6rem;
    margin-top: 0.5rem;
  }

  .Caption {
    padding: 3rem 2rem;
  }

  i {
    padding: 0.8rem;
  }

  .LC-card h4 {
    margin-top: 30px;
  }

  .MarketSection p {
    height: 110px !important;
  }

  .OurStory_Top {
    margin-bottom: 20px;
    padding-top: 1rem;
  }

  .OurStory_Top h1 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .OurStory_Top p {
    line-height: 1.4;
    font-size: 0.8rem;
  }

  .OurStory_Box {
    grid-template-columns: 1fr;
    width: auto;
  }

  .OurStory_Box .OurStory_TimeLine {
    padding-left: 0.6rem;
  }

  .OurStory_Box .OurStory_TimeLine div {
    grid-template-columns: 45px 40px auto;
  }

  .OurStory_Box .OurStory_TimeLine div h2 {
    font-size: 0.9rem;
  }

  .OurStory_Box .OurStory_TimeLine div p {
    font-size: 0.8rem;
  }

  .OurStory_Box .OurStory_Images div img {
    width: 100%;
  }

  .TeamSection_Box {
    display: grid;
    place-content: center;
    padding-bottom: 2rem;
    height: auto;
    max-height: unset;
  }

  .TeamSection_Box .TeamSection_Card {
    height: auto;
    margin: 0 auto;
    max-width: 350px;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: -2px 10px 15px rgba(0, 0, 0, 0.2);
    border: 1px solid #f1f1f1;
  }

  .TeamSection_Card_Left {
    padding: 2rem;
  }



  .TeamSection_Card_Left div {
    display: none;
  }

  .TeamSection_Card_Right {
    padding-bottom: 1rem;
  }

  .TeamSection_Card_Right div:nth-child(1) h2 {
    font-size: 1.3rem;
  }

  .TeamSection_Card_Right div:nth-child(3) {
    height: auto;
  }

  .TeamSection_Card_Right div:nth-child(3) p {
    font-size: 0.8rem;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    -webkit-box-orient: vertical;
  }

  .TeamSection_Card_Right div:nth-child(4) {
    display: flex;
  }

  .TeamSection_Card_Right div:nth-child(4) button {
    padding: 0.6rem;
    font-size: 0.9rem;
    width: 100%;
    max-width: 100%;
    border-radius: 6px;
  }

  .BlogcardstextData {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .Navstyle li {
    margin-top: 20px !important;
  }

  .BlogCardsImage {
    text-align: center;
    margin: 10px;
  }

  .BlogRightCardsImage {
    text-align: center;
    margin: 10px;
  }

  .BlogRightCardsImage img {
    border-radius: 15px;
  }

  .logCardsImage {
    width: 400px;
    height: 300px;
    margin: auto !important;
    margin-top: 20px !important;
  }

  .LC-card-image {
    margin: auto;
  }

  .Lc-mgmt-heading {
    text-align: center;
    margin-bottom: 20px;
  }

  .E-Billing-img {
    margin: auto;
    text-align: center;
  }

  .SetprocessImage {
    text-align: center;
  }

  .SetprocessImagetwo {
    text-align: center;
  }

  .CarosuelBg img {
    height: 450px;
  }
}

@media (max-width: 1200px) {
  .LogiCardbody {
    height: 620px !important;
  }

  .LogiCardbody h4 {
    height: 150px;
  }

  .MarketSection p {
    height: 220px !important;
  }

  .DropdownScroll {
    overflow-y: scroll;
    height: 250px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .logCardsImage {
    width: 400px;
    height: 300px;
    margin: auto !important;
    margin-top: 20px !important;
  }

  .logCardsImage img {
    border-radius: 15px;
  }
}

@media (max-width: 1400px) {
  .sec-5-icon {
    margin-top: 50px !important;
  }
}

@media (min-width: 992px) {
  .E-Billing-img {
    text-align: end;
  }

  .CompImage {
    text-align: start;
  }

  .SetprocessImage {
    text-align: end;
  }
}

/* Scroll Css */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-120px * 7));
  }
}

.slider::before,
.slider::after {
  position: absolute;
}

.slider .slide-track {
  -webkit-animation: scroll 20s linear infinite;
  animation: scroll 20s linear infinite;
  animation-iteration-count: infinite;
}

@media (max-width: 768px) {

  .SolutionInsure h5,
  h2,
  p {
    text-align: center;
  }

  .SolutionInsure img {
    margin-bottom: 30px;
  }

  .InsuranceCard div {
    margin: auto;
  }

  .Doc-Img {
    text-align: center;
  }

  .CompImage {
    text-align: center;
  }
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.67);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay img {
  width: 5rem;
  height: 5rem;
}

.modalshow {
  display: block;
}

.primary {
  color: white;
  background-color: #227B82;
  border-color: #227B82;
  border-bottom: 1px solid #227B82;
}

.close {
  background-color: transparent;
  border: 0;
  appearance: none;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}