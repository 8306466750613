body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
.circle {
    position: absolute;
    height: 1000px;
    width: 100%;
    clip-path: circle(550px at 1066px 183px);
    background: #3D49BB;
    opacity: 0.2;
}

#sec-1 {
    background: #16152D;
}

#sec-2,
#sec-3,
#sec-5,
#sec-6,
#sec-7 {
    background: #fff;
}

#sec-4 {
    background: #16152D;
}

#sec-f {
    background-color: #16152D;
    color: #fff;
}

.nav-menu {
    color: #fff;
    padding: 00px 15px !important;
}

.nav-menu:hover {
    color: #65E4FD;
}

.nav-main {
    padding: 10px 50px !important;
}
.head-button {
    padding: 10px 25px;
    border: none;
    background: #00B7C2;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    color: #fff;
}
.head-button:hover{
    color: #00B7C2;
    background-color: #fff;
}

.Seemore-outline {
    border: 1px solid #30B2CB;
    background: none;
    color: #fff;
    padding: 12px 28px !important;
    margin-left: 25px;
    border-radius: 23px;
}

.home-sec {
    color: #fff;
    /* padding: 125px 0 200px 28px !important; */
}

h1 {
    font-size: 51px !important;
    letter-spacing: -0.04em !important;
    font-weight: 600 !important;
}

.head-but {
    margin-top: 20px !important;
    font-weight: 500 !important;
}

.sec-b {
    padding: 70px 0 !important;
}

.sec-b-1 {
    border: 1px solid #b8b8b8 !important;
    border-radius: 20px;
    padding: 36px 25px !important;
    justify-content: space-between !important;
}

.sec-b-img {
    margin-left: 82px;
}

.item-sec {
    padding: 0px -15px !important;
    align-items: center;
}

h3 {
    font-weight: 500 !important;
    font-size: 40px !important;
}

.sec-c-img {
    height: 150px !important;
    width: 150px !important;
}

.sec-c-title {
    font-size: 24px !important;
    font-weight: 500;
}

.sec-c-title {
    color: #393939 !important;
}
.sec-c-text{
    font-size: 14px;
}
.sec-c-link {
    color: #00B7C2;
}
.sec-c-link:hover{
    color: linear-gradient(101.59deg, #097856 11.25%, #23A7B6 92.44%);;
}
.sec-c-card {
    border-radius: 15px !important;
}

.sec-4-box {
    position: relative;
    top: 100px;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    background: #fff;
    z-index: 1;
}

.sec-4-title {
    font-weight: 900;
    font-size: 44px;
    color: #23A7B6;
}
.sec-4-icon {
    height: 65px;
    width: 65px;
    background: #F4F5F7;
    border-radius: 33px;
    padding: auto !important;
    align-items: center;
    justify-content: center;
    display: flex;
}

.iconify {
    height: 21px;
    width: 21px;
}
.sec-5-card {
    background: #16152D;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
}

.sec-5-link {
    color: #025C7A !important;
    font-weight: 500 !important;
    font-size: 18px !important;
}

.sec-5-title {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
}
.sec-5-title img{
    height: 15px;
}


.sec-5-icon{
    height: 35px;
    width: 35px;
    background: #39393911;
    border-radius: 33px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    border: none !important;
}

.icon-5 {
    height: 17px;
    width: 17px;
}

.icon-6 {
    height: 18px;
    width: 18px;
}

.sec-6-main {
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 0px 8px 8px 0px;
}

.sec-7-card {
    background: #fafafa;
}

.sec-7-link {
    color: #3522AF;
}

.sec-7-text {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #263238;
}

.sec-7-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.03em;
    color: #263238;
}

.sec-8-icon {
    height: 30px;
    width: 30px;
    background: #ffffff1e;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 0 !important;
}

.nav-link {
    color: #D9DBE1!important;
}

.search-foot {
    background: #ffffff1e;
    border-radius: 8px;
}

.icon-foot {
    background: none !important;
    font-size: 14px !important;
    line-height: 0% !important;
    border: none !important;
    box-shadow: none !important;
    color: #FFF !important;
}

.icon-butt {
    height: 16px;
    width: 16px;
}

input:active,
input:focus {
    border: none !important;
}