
.Marketplace{
    min-height: 800px;
    background: -webkit-gradient(linear, left top, right top, from(#f5f5f5), color-stop(rgba(245, 245, 245, 0.9)), to(rgba(255, 255, 255, 0.808))), url(../images/map.6d5ec8f35e70663f6953c5bed487692c.jpg);
    background: -webkit-linear-gradient(left, #f5f5f5, rgba(245, 245, 245, 0.9), rgba(255, 255, 255, 0.808)), url(../images/map.6d5ec8f35e70663f6953c5bed487692c.jpg);
    background: linear-gradient(
90deg
, #f5f5f5, rgba(245, 245, 245, 0.9), rgba(255, 255, 255, 0.808)), url(../images/map.6d5ec8f35e70663f6953c5bed487692c.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
.ship{
    padding-top: 5.5rem;
}
.ship p{
    text-align: center;
    margin-bottom: 16px;
    padding: 8px 16px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    color: #6e7e92;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #ddd;
    border-radius: 24px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.ship h1{
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;;
 }
.trades-btn{
    box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    width: fit-content;
    padding: 0.4rem;
    border-radius: 12px;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border: 1px solid #eee;
    background: #fff;
}
.btn-info{
    background-color: #28224B !important;
    color:#fff !important;
}

.colorBlue{
    color: #28224B !important;
}
#trackOne, #trackTwo{
    border-radius: 20px !important;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 10%) !important;
}
.accordionitem{
    border-radius: 10px !important;
}
#Track-headingOne, #Track-headingTwo{
    border-radius: 10px !important;
}
.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.cardheader{
    border-bottom: none !important;
    background: RGB(63, 151, 151,0.1);
    color: RGB(63, 151, 151);
}
.liRight{
    color: RGB(63, 151, 151);
}
.tracking-place{
    margin-right: 4.6rem; 
    font-size: 10px;
    font-weight: 700;
}
.tracking-circle{
    padding:3px !important;
    margin-right: 5rem !important;
    margin-top: -17px !important;
}
.progress{
    height: 1px !important; 
    width: 93% !important;
    margin-top: 14px !important;
}
.market-filters{
    border: none !important;
}
.accordionborder{
    border: none !important;
}
.depature-circle{
    padding: 3px !important;
    margin-right: 215px !important;
    margin-top: -17px !important;
}
.depature-circle2{
    padding:3px !important;
    margin-top: -17px !important;
}
.depature-place{
    margin-right:  88px;
    font-size: 10px;
    font-weight: 700;
}
.btndate{
    font-weight: 700 !important;
    background: #ebf8fc !important;
    color: #28224B !important;
    font-size: 12px !important;
    padding: 4px !important;
}
.form-check-input:checked {
    background-color: #28224B !important;
    border-color: #28224B !important;
}
.progress2{
    height: 1px !important; 
    width: 99% !important;
    margin-top: 14px !important;
}
@media (max-width: 991.98px) { 
    .margintop{
        margin-top: 2rem !important;
    }
} 
.InputStyle{
    box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    border: 1px solid #d6d6d4;
    border-radius: 15px;
  
}
.InputStyle input{
    color: #000 !important;
}
.City span{
    background: transparent;
    border: none;
}
.City input{
    color: #000 !important;
    border: none !important;
}
.InputStyle select{
    border: none !important;
}
.SearchIcon{
    height: 45px !important;
    width: 50px !important;
    background-color: #28224B !important;
    color: #fff;
    font-size: 10px;
}
.SearchIcon{
    border-radius: 10px !important;
    box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
}